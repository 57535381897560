export default class DataService {
    _data = {
        projectRepo: "https://github.com/InsafKhamzin/portfolio",
        react95Repo: "https://github.com/React95/React95",
        items: [
            {
                id: 'about',
                name: 'About.txt',
                icon: 'info_bubble',
                content: {
                    paragraphs: [
                        "Hey, Vishesh Sanghani here ! I am a Software Developer based in Coimbatore, India,  I am very excited to start my career as Frontend Developer. For the last several years I've been working as a FrontEnd Engineer / Full Stack developer.",
                        "I believe that friendly and respectful communication within a team is key to the company's success. Within the years, I developed an excellent sense for pixel perfect designs, my main goal is provide the best possible solution and, in the meanwhile, create robust and scalable web applications using the latest methodologies",
                        "The main technology I have been using is ReactJS, VueJS, NodeJs. But recently I fell in love with Flutter and I use it for most of my side projects. Moreover, I am in really fascinated with Block Chain Technology and Ethical Hacking.",
                        "When I am not in front of the computer, I usually play football, write random stuff and reading fiction."
                    ]
                }
            },
            {
                id: 'resume',
                name: 'Resume.txt',
                icon: 'notepad_2',
                content: {
                    resumeLink: "https://drive.google.com/file/d/1Xlld4YK_6QM5tYb4oytWz6LmlZxpNIj1/view?usp=sharing",
                    workExperience: [
                        {
                            jobTitle: "Senior Software Developer",
                            company: "Kuver.in",
                            location: "Bangalore",
                            period: "Nov 2021 - Present",
                            accomplishments: [
                                "Worked on the Fixed Deposit and Mutual Fund flow.",
                                "Contribution to the product that is going to be launched in Amazon for a targer audience of 20 Million.",
                                "Technologies used: VueJs, Custome Styling, AWS"
                            ]
                        },
                        {
                            jobTitle: "Full Stack Developer",
                            company: "Codeyoung",
                            location: "Bangalore",
                            period: "Oct 2020 - Nov 2021",
                            accomplishments: [
                                "Contributation in the design and architecture of the apps and database.",
                                "Worked on the Anayltics of the sites, which helped for a planned data driven approach.",
                                "Worked on Integration of 3rd party payment vendors. Eg : RazorPay, PhonePe.",
                                "Technologies used: ReactJS, VueJs, NodeJs, MySQL, TailWindCSS, Material UI"
                            ]
                        },
                        {
                            jobTitle: "Frontend Engineer",
                            company: "Perficient",
                            location: "Chennai",
                            period: "Nov 2019 – Oct 2020",
                            accomplishments: [
                                "Automated multiple services, resulting in a digital approach.",
                                "Followed a strict Test-Driven approach for development.",
                                "Worked on multiple apps ranging from FTL applications to full-blown React application",
                                "Technologies used: ReactJS, JavaScript - ES6 & ES5, TypeScript, FreeMarker Template Language, Java, HTML5, CSS3, Jira"
                            ]
                        },
                        {
                            jobTitle: "Frontend Developer",
                            company: "Infosys Pvt. Ltd.",
                            location: "Pune / Chennai",
                            period: "Oct 2017 – Nov 2019",
                            accomplishments: [
                                "Created multiple Dashboards for the product management of the services.",
                                "In the team that made the admin modules for the application.",
                                "Automated the process of Termination of services which helped the client in cost reduction by 20%.",
                            ]

                        }
                    ],
                    education: [
                        {
                            credit: "Bachelors of Technology in Information Technology",
                            place: "Krishna College Of Technology, Coimbatore",
                            gpa: "7.5/10 GPA",
                            period: "Aug 2013 – Apr 2017"
                        },
                        {
                            credit: "High School",
                            place: "Sri Baldevdas Vidya Mandir School",
                            gpa: "7.8/10 GPA",
                            period: "Apr 2011 – May 2013"
                        }
                    ]
                }
            },
            {
                id: "projects",
                name: "Projects.txt",
                icon: "flying_through_space_100",
                content: {
                    projects: [
                         {
                            title: "Codeyoung.com",
                            description: "Landing page of the company ( codeyoung.com ) , codeyoung is a pre-seed Edu-tech where kids can learn coding and create projects of their own.",
                            myRole: "Revamped the UI/UX multiple times to increase customer engagement. Introduced the blog and course description feature.",
                            techStack:"NuxtJS, TailWind, Strapi, HTML5, CSS3",
                            url: "https://www.codeyoung.com/"
                        },
                          {
                            title: "Book-a-Demo",
                            description: "Demo booking page of the product ( codeyoung.com )",
                            myRole: "Build the product from scratch, revamped the UI/UX multiple times to increase customer engagement which resulted in booking conversions from 7% to 13.7%.",
                            techStack: "ReactJS, MaterialUI, HTML5, CSS3, NodeJS, MySQL",
                            url: "https://book-a-demo.codeyoung.com/#/"
                        },
                        {
                            title: "TDAmeritrade",
                            description: "TD Ameritrade is a broker that offers an electronic trading platform for the trade of financial assets including common stocks, preferred stocks, futures contracts, exchange-traded funds, options, cryptocurrency, mutual funds, and fixed income investments. It also provides margin lending, and cash management services.",
                            myRole: "Worked with the US counterparts to develop and maintain the GUI for the application. Increased stability of the application by identifying and fixing UI/functional issues present in the application, reducing the number of bugs reported from 139 to 91.",
                            techStack: "ReactJS, JavaScript - ES6 & ES5, TypeScript, Java, HTML5, CSS3, Jira",
                            url: "https://www.tdameritrade.com/home.page"
                        },
                        {
                            title: "NavkarSarvar ( A charitable Organization )",
                            description: `Shri Navakar Sarvar Kendra was initiated by Vaiyavachcha Bhakti of Sadhu-Sadhviji by treating them and serving Ayurvedic medicine. 
                            Gradualy the activity was intensified. Jeevdaya Activity was added to save life of cattle and treat them if needed.
                            As per the Jain Philosophy the above activities are not enough.To widen the activity base we have added Sadharmik Bhakti.`,
                            myRole: `Developed the application in React from the base. Team of two worked on the entire development and testing process.
                            Revamped the entire website into a modern-day application. Payment Intergratoin using PayTM payment Gateway`,
                            techStack: "ReactJS, JavaScript - ES6 & ES5, MySQL , HTML5, CSS3, Bootstrap , Semantic-React UI",
                            url: "https://navkarsarvar.netlify.app"
                        },
                        {
                            title: "Manchester United Fixtures Application",
                            description: "An application made for the love of the club and sport. The site reveals the data about the next fixture and match detalis",
                            techStack: "ReactJS, JavaScript - ES6 & ES5, HTML5, CSS3, Bootstrap , Semantic-React UI , Third-Party Apis",
                            url: "https://manunited.netlify.app",
                        },

                        {
                            title: "My Portfolio Site",
                            description: "My personal website in old-school Windows95 design.",
                            techStack: "React, Netlify",
                        },
                    ]
                }
            },
            {
                id: 'skills',
                name: 'Skills.txt',
                icon: 'progman_11',
                content: {
                    hard: [
                        {
                            name: "React.js/Redux",
                            progress: 95
                        },
                        {
                            name: "Vue.js",
                            progress: 80
                        },
                        {
                            name: "Vanilla JavaScript",
                            progress: 80
                        },
                        {
                            name: "Node.js",
                            progress: 75
                        },
                        {
                            name: "AWS",
                            progress: 75
                        },
                        {
                            name: "MongoDB",
                            progress: 80
                        },
                        {
                            name: "HTML",
                            progress: 90
                        },
                        {
                            name: "CSS / SCSS",
                            progress: 85
                        },
                        {
                            name: "Manchester United Fan",
                            progress: 100
                        }
                    ],
                    soft: "Analytical thinking, Teamwork, Creative Problem solving, Mangement, Leadership, Entrepreneurship"
                }
            },
            {
                id: 'contact',
                name: 'Contact.txt',
                icon: 'inetcfg_2301',
                content: {
                    emailText: "If you want to hire me or invite to a project, just email me on ",
                    email: "vishesh.sanghani95@gmail.com",
                    socialText: "Or you can reach me out through social media:",
                    social: [
                        {
                            name: "FaLinkedin",
                            link: "https://www.linkedin.com/in/vishesh-sanghani/"
                        },
                        {
                            name: "FaGithub",
                            link: "https://github.com/visheshsanghani"
                        },
                        {
                            name: "FaInstagram",
                            link: "https://www.instagram.com/visheshsanghani"
                        },
                        {
                            name: "FaTwitter",
                            link: "https://twitter.com/vishsanghani"
                        },
                        {
                            name: "FaMedium",
                            link: "https://www.quora.com/profile/Vishesh-Sanghani-1"
                        }
                    ]
                }
            },
            {
                id: 'books',
                name: 'Books.txt',
                icon: 'msnstart_100',
                content: {
                    topFive: [
                        '1 . Tuesdays with Morrie - Mitch Albom',
                        '2 . A Thousand Splendid Suns - Khaleed Hosieni',
                        '3 . The Kite Runner - Khaleed Hosieni',
                        '4 . Animal Farm - George Orwell',
                        '5 . Raavan: Enemy of Aryavarta - Amish Tripathi',
                    ]
                }
            },
        ]
    }

    getItems() {
        return this._data.items.map(({ id, name, icon }) => ({ id, name, icon }));
    }

    getItem(id) {
        return this._data.items.find(x => x.id === id);
    }

    getProjectInfo() {
        return {
            projectRepo: this._data.projectRepo,
            react95Repo: this._data.react95Repo
        };
    }
}
