import React, { useState } from 'react'
import { TaskBar, List, Modal, Button } from '@react95/core'
import styled from 'styled-components'
import Notepad from './Notepad'


const Link = styled.a`
    text-decoration: none;
    color: inherit;
`

function Taskbar() {
    const isMobile = window.innerWidth < 850;
    const [selectedItem, setSelectedItem] = useState(null);
    const [notepadOpened, toggleNotepad] = useState(false);
    const [shutDown, setShutDown] = useState(false);
    const [shutDown2, setShutDown2] = useState(false);
    const closeNotepad = () => {
        toggleNotepad(false);
    };

    const openNotepad = (item) => {
        setSelectedItem(item)
        toggleNotepad(true);
    };

    const shutDownTrue = () => {
        setShutDown(true)
    }

    const closeShutDown = () => {
        setShutDown(false);
    };

    const closeShutDown2 = () => {
        setShutDown2(false);
        closeShutDown();
    };


    return (
        <div>
            {
                notepadOpened && (
                    <Notepad closeNotepad={closeNotepad} selectedItem={selectedItem} isMobile={isMobile} />
                )
            }
            {
                shutDown && (
                    <Modal
                        icon="user_4"
                        title={'Shut Down'}
                        closeModal={closeShutDown}
                        buttons={[{ value: "Close", onClick: closeShutDown }]}
                        style={{
                            left: isMobile ? '5%' : '40%',
                            top: isMobile ? '3%' : '15%',
                            width: isMobile ? '90%' : 450,
                        }}>
                        <p>
                            Do you want to Shut down ?
                        </p>
                        <Button style={{ fontSize: "14px", marginLeft: 100, marginRight: 100 }} onClick={() => setShutDown2(true)} className="pointer" >Shut Down</Button>
                    </Modal>
                )

            }
            {
                shutDown2 && (
                    <Modal
                        icon="user_4"
                        title={'Shut Down'}
                        closeModal={setTimeout(() => { closeShutDown2() }, 6000)}
                        style={{
                            left: isMobile ? '5%' : '30%',
                            top: isMobile ? '3%' : '25%',
                            width: isMobile ? '90%' : 450,
                        }}>
                        <p>Are you serious ? When was the last time, you saw a web page closing itself.</p>
                        <p>Click on the top - right X button.</p>
                    </Modal>)
            }

            <TaskBar
                list={
                    <List>
                        <List.Item className="pointer" icon="progman_13">
                            <Link href="https://www.instagram.com/visheshsanghani" target="_blank">My Instagram</Link>
                        </List.Item>
                        <List.Divider />
                        <List.Item className="pointer" icon="folder_file">
                            <Link href="https://github.com/visheshsanghani/" target="_blank">My GitHub</Link>
                        </List.Item>
                        <List.Divider />
                        <List.Item className="pointer" icon="msnstart_100" onClick={() => openNotepad({ id: "books", name: "Books.txt", icon: "msnstart_100" })}>
                            My Favourite Novels
                        </List.Item>
                        <List.Divider />
                        <List.Item className="pointer" icon="user_4" onClick={() => shutDownTrue()}>
                            Shut Down
                        </List.Item>
                        <List.Divider />
                    </List>
                }
            />
        </div>
    )
}

export default Taskbar
