import React from 'react'

function Books({ content }) {
    const { topFive } = content;
    return (
        <div>
            <h2>My Top Five Fictional Novels</h2>
            {
                topFive.map((h) => {
                    return (
                        <p key={h}>{h}</p>
                    )
                }
                )
            }
            <br />
        </div>
    )
}

export default Books
